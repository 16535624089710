let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

let Envname = "prod";

if (Envname == "prod") {

  const API_URL = "https://prodapi.vforrce.com"; //live
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x9d22f85528f214e52accdbb36049fdf929feb600",
    VForceContract: "0xa27b7552C21B2E9fF933ac3670C0d78201908B9B",
    admin_address: "0xb071fC2618f5fFDBa5527D6d72628105E1288E9E",
    // admin_address: "0x57E241D5C192735b8A00327C162898737cb9B3aA",

    ScanUrl: "https://bscscan.com/tx/",
    NetworkId: 56,
    decimals: 1e18,
  };

} else if (Envname == "demo") {

  const API_URL = "https://yezbitcoinstakingapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
    VForceContract: "0x4375e725380239f060123Ed371c5B51C8790Bd6F",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    toasterOption: toasterOption,
    BUSDcontract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
    VForceContract: "0xe9669f11D245635e7fA5c63b29895CF452273c1e",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
  };
}

export default key;
